<template>
  <div class="viestit">
    <!-- Off-canvas -->
    <v-navigation-drawer
      ref="offCanvas"
      v-model="showOffCanvas"
      width="450"
      app
      right
      temporary
    >
      <Spinner v-if="loadingOffCanvas" />
      <OffCanvasMessage
        v-else
        v-model="selectedItem.item"
        @close-button-click="setShowOffCanvas"
        @messageSubmit="refresh(true)"
        @msg-mark-as-unread="refresh"
        @msg-mark-as-read="refresh"
      />
    </v-navigation-drawer>

    <!-- Top bar -->
    <ViewSelaaTopBarBase
      pikahaun-placeholder-teksti="Hae (asianro, laskunro, lähettäjä, päämies, id)"
      :vuex-module-name="vuexModuleName"
      :search-panel="(false)"
    />

    <!-- Tabs -->
    <ViewSelaaTabs
      :vuex-module-name="vuexModuleName"
    />

    <!-- Toolbar -->
    <ViewSelaaToolbar
      :vuex-module-name="vuexModuleName"
      item-partitiivi="viestiä"
    />

    <!-- Toiminnot -->
    <v-container
      v-if="$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_kuittaus')"
      fluid
      class="pt-0 px-6 pb-4 mb-0"
    >
      <MenuSelectedMessages
        v-if="lahetetytTabEiValittu"
        :disabled="!checkedItems.length"
        :item-count="checkedItems.length"
        @markCheckedAsRead="markCheckedAsRead"
        @markCheckedAsUnRead="markCheckedAsUnRead"
      />
    </v-container>

    <!-- Messages -->
    <v-data-table
      v-model="checkedItems"
      class="v-data-table--striped"
      :headers="tableHeaders"
      :items="items"
      :hide-default-footer="items.length < 10"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50, 100],
      }"
      :multi-sort="true"
      :server-items-length="itemsTotal"
      :options.sync="pagination"
      :loading="loading"
      item-key="id"
      loading-text=""
      no-data-text=""
      :show-select="lahetetytTabEiValittu"
      :dense="tiivisNakyma"
    >
      <template v-slot:progress>
        <v-progress-linear
          color="primary"
          indeterminate
        />
      </template>

      <template v-slot:item="props">
        <TableRow
          :clickable="(true)"
          :data="props.item"
          :selected-item="selectedItem.item"
          :checked="(checkedItems.some(checkedItem => checkedItem.id == props.item.id))"
          :fade="loading"
          :bold="!props.item.luettu && props.item.lahettaja.is_staff"
          @contextmenu.prevent="openMsgCtxMenu($event, props.item)"
        >
          <td v-if="lahetetytTabEiValittu">
            <v-checkbox
              color="primary"
              :input-value="checkedItems.some(checkedItem => checkedItem.id == props.item.id)"
              @change="kasitteleValinta($event, props.item)"
            />
          </td>
          <td
            class="text-no-wrap px-0"
            @click="setSelectedItem({ item: props.item })"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <StatusIcon
                  v-if="props.item.ikoni_ja_vari"
                  :icon="props.item.ikoni_ja_vari.ikoni"
                  :size="tiivisNakyma ? 'small' : 'medium'"
                  :hover-icon="('open-in-new')"
                  :bg-color="props.item.ikoni_ja_vari.vari"
                  :event-listener="on"
                  @click="(props.item.lasku ? $router.push({ name: 'laskutus-nayta', params: { id: props.item.lasku.id } }) : $router.push({ name: 'perinta-nayta', params: { id: props.item.toimeksianto } }))"
                />
              </template>
              <template v-slot:default>
                <span v-if="props.item.lasku">Avaa lasku</span>
                <span v-else-if="props.item.ostolasku">Avaa ostolasku</span>
                <span v-else-if="props.item.toimeksianto">Avaa toimeksianto</span>
                <span v-else>Avaa tietue</span>
              </template>
            </v-tooltip>
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item: props.item })"
          >
            {{ props.item.id }}
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item: props.item })"
          >
            {{ props.item.lahettaja.naytettava_nimi }}

            <v-chip
              v-if="props.item.lahettaja.henkilokuntatunniste"
              :small="(true)"
              color="pink darken-2"
              text-color="white"
              :disabled="(true)"
              class="ma-0 ml-1"
            >
              {{ props.item.lahettaja.henkilokuntatunniste }}
            </v-chip>
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item: props.item })"
          >
            {{ props.item.paamies.nimi }}
          </td>
          <td
            class="text-no-wrap"
            @click="setSelectedItem({ item: props.item })"
          >
            <span v-if="props.item.toimeksianto">
              Toimeksianto {{ props.item.toimeksianto }}
            </span>
            <span v-else-if="props.item.lasku">
              Lasku {{ props.item.lasku.nro }}
            </span>
            <span v-else-if="props.item.ostolasku">
              Ostolasku {{ props.item.ostolasku.nro }}
            </span>
          </td>
          <td
            class="max-width py-2"
            @click="setSelectedItem({ item: props.item })"
          >
            <nl2br
              tag="span"
              :text="cleanLineBreaks(props.item.viesti, { strict: true, truncate: true }, )"
            />
          </td>
          <td
            class="text-no-wrap text-right"
            @click="setSelectedItem({ item: props.item })"
          >
            {{ $date(props.item.luotu, 'D.M.YYYY HH:mm') }}
          </td>
        </TableRow>
      </template>
    </v-data-table>

    <!-- Message context menu -->
    <v-menu
      v-model="msgCtxMenuVisible"
      :position-x="msgCtxMenuX"
      :position-y="msgCtxMenuY"
      absolute
      offset-y
    >
      <v-list>
        <v-list-item
          @click="markCheckedAsRead"
        >
          <v-list-item-avatar>
            <v-icon>check</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Merkitse käsitellyksi
          </v-list-item-title>
        </v-list-item>

        <v-list-item
          @click="markCheckedAsUnRead"
        >
          <v-list-item-avatar>
            <v-icon>clear</v-icon>
          </v-list-item-avatar>
          <v-list-item-title>
            Merkitse käsittelemättömäksi
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <!-- Bottom toolbar -->
    <v-toolbar
      v-if="(itemsTotal >= 16)"
      class="my-6 pa-2 transparent"
      flat
    >
      <MenuSelectedMessages
        v-if="lahetetytTabEiValittu"
        :disabled="!checkedItems.length"
        :item-count="checkedItems.length"
        @markCheckedAsRead="markCheckedAsRead"
        @markCheckedAsUnRead="markCheckedAsUnRead"
      />
      <v-spacer />
      <Pagination
        v-if="(pagesTotal > 1)"
        v-model="pagination.page"
        :length="pagesTotal"
        :disabled="loading"
      />
      <v-spacer />
    </v-toolbar>
  </div>
</template>

<script>

import _ from 'lodash'
import {
  OffCanvasMessage,
  Pagination,
  MenuSelectedMessages,
  ViewSelaaTabs,
  ViewSelaaToolbar,
  ViewSelaaTopBarBase,
} from '@/components/'
import ViewSelaaMixin from '@/mixins/ViewSelaaMixin'
import cleanLineBreaks from '@/utils/filters/cleanLineBreaks'
import { TableHeadersViestit } from '@/utils/tableHeaders'

export default {
  name: 'ViestitSelaa',
  components: {
    OffCanvasMessage,
    Pagination,
    MenuSelectedMessages,
    ViewSelaaTabs,
    ViewSelaaToolbar,
    ViewSelaaTopBarBase,
  },
  mixins: [ViewSelaaMixin],
  data () {
    return {
      cleanLineBreaks: cleanLineBreaks,
      tableHeaders: TableHeadersViestit,
      checkedItems: [],
      uusiViestiFormLoading: false,
      msgCtxMenuVisible: false,
      msgCtxMenuX: 0,
      msgCtxMenuY: 0,
    }
  },
  computed: {
    lahetetytTabEiValittu () {
      return this.currentTab !== 'lahetetyt'
    },
  },
  watch: {
    currentTab () {
      this.checkedItems = []
    },
    msgCtxMenuVisible: function (val, oldVal) {
      if (oldVal && !val) {
        this.checkedItems = []
      }
    },
  },
  methods: {
    async markCheckedAsRead () {
      try {
        if (!this.checkedItems.length) return

        this.loading = true

        const idArray = this.checkedItems.map(item => {
          return item.id
        })

        const request = await this.$doRequestWithTimeout(
          this.$api.Viestit,
          {
            method: 'POST',
            url: 'toiminnot/merkitse_luetuksi/',
            body: {
              viestit: idArray,
            },
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$doRequestWithTimeout(
          this.$api.UserLukemattomatViestitKpl
        )

        await this.loadItems()
        this.checkedItems = []
      } catch (e) {
        this.$oletusVirheenkasittely(e)

        this.loading = false
      }
    },

    async markCheckedAsUnRead () {
      try {
        if (!this.checkedItems.length) return

        this.loading = true

        const idArray = this.checkedItems.map(item => {
          return item.id
        })

        const request = await this.$doRequestWithTimeout(
          this.$api.Viestit,
          {
            method: 'POST',
            url: 'toiminnot/merkitse_lukemattomaksi/',
            body: {
              viestit: idArray,
            },
          },
          'doSingleRequest',
        )

        if (!request.success) throw new this.$HttpError(request)

        await this.$doRequestWithTimeout(
          this.$api.UserLukemattomatViestitKpl
        )

        await this.loadItems()
        this.checkedItems = []
      } catch (e) {
        this.$oletusVirheenkasittely(e)

        this.loading = false
      }
    },

    openMsgCtxMenu (event, viesti) {
      if (!this.$permission.checkSinglePerm('onlinerestapi.onlinerestapi_viesti_kuittaus')) return

      this.checkedItems = [viesti]
      this.msgCtxMenuX = event.clientX
      this.msgCtxMenuY = event.clientY
      this.msgCtxMenuVisible = true
    },

    async refresh (scrollToBottom = false) {
      // eslint-disable-next-line
      return new Promise (async (resolve) => {
        await this.loadItems()
        await this.setSelectedItem(this.selectedItem, true)

        if (scrollToBottom) {
          this.$nextTick().then(() => {
            this.$refs.offCanvas.$el.scrollTop = this.$refs.offCanvas.$el.scrollHeight
          })
        }

        resolve()
      })
    },
    kasitteleValinta (checked, item) {
      if (checked) {
        this.checkedItems.push(item)
      } else {
        const taulukko = this.checkedItems
        _.remove(taulukko, (checkedItem) => {
          return checkedItem.id === item.id
        })
        this.checkedItems = []
        this.$nextTick(() => {
          this.checkedItems = taulukko
        })
      }
    }
  },
}

</script>

<style lang="scss" scoped>

/* Korjaa marginin joka kasvattaa data-tablen rivien korkeutta */
.v-input--selection-controls {
  margin: 0 0px -16px -4px;
}

</style>
