var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "viestit" },
    [
      _c(
        "v-navigation-drawer",
        {
          ref: "offCanvas",
          attrs: { width: "450", app: "", right: "", temporary: "" },
          model: {
            value: _vm.showOffCanvas,
            callback: function ($$v) {
              _vm.showOffCanvas = $$v
            },
            expression: "showOffCanvas",
          },
        },
        [
          _vm.loadingOffCanvas
            ? _c("Spinner")
            : _c("OffCanvasMessage", {
                on: {
                  "close-button-click": _vm.setShowOffCanvas,
                  messageSubmit: function ($event) {
                    return _vm.refresh(true)
                  },
                  "msg-mark-as-unread": _vm.refresh,
                  "msg-mark-as-read": _vm.refresh,
                },
                model: {
                  value: _vm.selectedItem.item,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedItem, "item", $$v)
                  },
                  expression: "selectedItem.item",
                },
              }),
        ],
        1
      ),
      _c("ViewSelaaTopBarBase", {
        attrs: {
          "pikahaun-placeholder-teksti":
            "Hae (asianro, laskunro, lähettäjä, päämies, id)",
          "vuex-module-name": _vm.vuexModuleName,
          "search-panel": false,
        },
      }),
      _c("ViewSelaaTabs", {
        attrs: { "vuex-module-name": _vm.vuexModuleName },
      }),
      _c("ViewSelaaToolbar", {
        attrs: {
          "vuex-module-name": _vm.vuexModuleName,
          "item-partitiivi": "viestiä",
        },
      }),
      _vm.$permission.checkSinglePerm(
        "onlinerestapi.onlinerestapi_viesti_kuittaus"
      )
        ? _c(
            "v-container",
            { staticClass: "pt-0 px-6 pb-4 mb-0", attrs: { fluid: "" } },
            [
              _vm.lahetetytTabEiValittu
                ? _c("MenuSelectedMessages", {
                    attrs: {
                      disabled: !_vm.checkedItems.length,
                      "item-count": _vm.checkedItems.length,
                    },
                    on: {
                      markCheckedAsRead: _vm.markCheckedAsRead,
                      markCheckedAsUnRead: _vm.markCheckedAsUnRead,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("v-data-table", {
        staticClass: "v-data-table--striped",
        attrs: {
          headers: _vm.tableHeaders,
          items: _vm.items,
          "hide-default-footer": _vm.items.length < 10,
          "footer-props": {
            itemsPerPageOptions: [10, 25, 50, 100],
          },
          "multi-sort": true,
          "server-items-length": _vm.itemsTotal,
          options: _vm.pagination,
          loading: _vm.loading,
          "item-key": "id",
          "loading-text": "",
          "no-data-text": "",
          "show-select": _vm.lahetetytTabEiValittu,
          dense: _vm.tiivisNakyma,
        },
        on: {
          "update:options": function ($event) {
            _vm.pagination = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "progress",
            fn: function () {
              return [
                _c("v-progress-linear", {
                  attrs: { color: "primary", indeterminate: "" },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "TableRow",
                  {
                    attrs: {
                      clickable: true,
                      data: props.item,
                      "selected-item": _vm.selectedItem.item,
                      checked: _vm.checkedItems.some(function (checkedItem) {
                        return checkedItem.id == props.item.id
                      }),
                      fade: _vm.loading,
                      bold: !props.item.luettu && props.item.lahettaja.is_staff,
                    },
                    on: {
                      contextmenu: function ($event) {
                        $event.preventDefault()
                        return _vm.openMsgCtxMenu($event, props.item)
                      },
                    },
                  },
                  [
                    _vm.lahetetytTabEiValittu
                      ? _c(
                          "td",
                          [
                            _c("v-checkbox", {
                              attrs: {
                                color: "primary",
                                "input-value": _vm.checkedItems.some(function (
                                  checkedItem
                                ) {
                                  return checkedItem.id == props.item.id
                                }),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.kasitteleValinta(
                                    $event,
                                    props.item
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap px-0",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [
                        _c("v-tooltip", {
                          attrs: { top: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    props.item.ikoni_ja_vari
                                      ? _c("StatusIcon", {
                                          attrs: {
                                            icon: props.item.ikoni_ja_vari
                                              .ikoni,
                                            size: _vm.tiivisNakyma
                                              ? "small"
                                              : "medium",
                                            "hover-icon": "open-in-new",
                                            "bg-color":
                                              props.item.ikoni_ja_vari.vari,
                                            "event-listener": on,
                                          },
                                          on: {
                                            click: function ($event) {
                                              props.item.lasku
                                                ? _vm.$router.push({
                                                    name: "laskutus-nayta",
                                                    params: {
                                                      id: props.item.lasku.id,
                                                    },
                                                  })
                                                : _vm.$router.push({
                                                    name: "perinta-nayta",
                                                    params: {
                                                      id: props.item
                                                        .toimeksianto,
                                                    },
                                                  })
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function () {
                                  return [
                                    props.item.lasku
                                      ? _c("span", [_vm._v("Avaa lasku")])
                                      : props.item.ostolasku
                                      ? _c("span", [_vm._v("Avaa ostolasku")])
                                      : props.item.toimeksianto
                                      ? _c("span", [
                                          _vm._v("Avaa toimeksianto"),
                                        ])
                                      : _c("span", [_vm._v("Avaa tietue")]),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(props.item.id) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(props.item.lahettaja.naytettava_nimi) +
                            " "
                        ),
                        props.item.lahettaja.henkilokuntatunniste
                          ? _c(
                              "v-chip",
                              {
                                staticClass: "ma-0 ml-1",
                                attrs: {
                                  small: true,
                                  color: "pink darken-2",
                                  "text-color": "white",
                                  disabled: true,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.item.lahettaja.henkilokuntatunniste
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(props.item.paamies.nimi) + " ")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [
                        props.item.toimeksianto
                          ? _c("span", [
                              _vm._v(
                                " Toimeksianto " +
                                  _vm._s(props.item.toimeksianto) +
                                  " "
                              ),
                            ])
                          : props.item.lasku
                          ? _c("span", [
                              _vm._v(
                                " Lasku " + _vm._s(props.item.lasku.nro) + " "
                              ),
                            ])
                          : props.item.ostolasku
                          ? _c("span", [
                              _vm._v(
                                " Ostolasku " +
                                  _vm._s(props.item.ostolasku.nro) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "max-width py-2",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [
                        _c("nl2br", {
                          attrs: {
                            tag: "span",
                            text: _vm.cleanLineBreaks(props.item.viesti, {
                              strict: true,
                              truncate: true,
                            }),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text-no-wrap text-right",
                        on: {
                          click: function ($event) {
                            return _vm.setSelectedItem({ item: props.item })
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$date(props.item.luotu, "D.M.YYYY HH:mm")
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            },
          },
        ]),
        model: {
          value: _vm.checkedItems,
          callback: function ($$v) {
            _vm.checkedItems = $$v
          },
          expression: "checkedItems",
        },
      }),
      _c(
        "v-menu",
        {
          attrs: {
            "position-x": _vm.msgCtxMenuX,
            "position-y": _vm.msgCtxMenuY,
            absolute: "",
            "offset-y": "",
          },
          model: {
            value: _vm.msgCtxMenuVisible,
            callback: function ($$v) {
              _vm.msgCtxMenuVisible = $$v
            },
            expression: "msgCtxMenuVisible",
          },
        },
        [
          _c(
            "v-list",
            [
              _c(
                "v-list-item",
                { on: { click: _vm.markCheckedAsRead } },
                [
                  _c(
                    "v-list-item-avatar",
                    [_c("v-icon", [_vm._v("check")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v(" Merkitse käsitellyksi ")]),
                ],
                1
              ),
              _c(
                "v-list-item",
                { on: { click: _vm.markCheckedAsUnRead } },
                [
                  _c(
                    "v-list-item-avatar",
                    [_c("v-icon", [_vm._v("clear")])],
                    1
                  ),
                  _c("v-list-item-title", [
                    _vm._v(" Merkitse käsittelemättömäksi "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.itemsTotal >= 16
        ? _c(
            "v-toolbar",
            { staticClass: "my-6 pa-2 transparent", attrs: { flat: "" } },
            [
              _vm.lahetetytTabEiValittu
                ? _c("MenuSelectedMessages", {
                    attrs: {
                      disabled: !_vm.checkedItems.length,
                      "item-count": _vm.checkedItems.length,
                    },
                    on: {
                      markCheckedAsRead: _vm.markCheckedAsRead,
                      markCheckedAsUnRead: _vm.markCheckedAsUnRead,
                    },
                  })
                : _vm._e(),
              _c("v-spacer"),
              _vm.pagesTotal > 1
                ? _c("Pagination", {
                    attrs: { length: _vm.pagesTotal, disabled: _vm.loading },
                    model: {
                      value: _vm.pagination.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "page", $$v)
                      },
                      expression: "pagination.page",
                    },
                  })
                : _vm._e(),
              _c("v-spacer"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }